html,
body,
#root {
  height: 100vh;
  overflow: hidden;
  padding: 0;
}

#root {
  display: flex;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

input {
  outline: 0;
}

